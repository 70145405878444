<script>
import { defineComponent } from '@vue/composition-api'
import { useEndpoints } from '@/composables'

// import TitlePlus from '@/components/ui/TitlePlus.vue'

import { TaskList } from '@/composites/task'

import { getAddEditObject } from './helpers'
import { useEdgeStack } from '@/plugins/edgeStack'

export default defineComponent({
  name: 'ScooterIndex',

  components: {
    TaskList,
    BaseLayout: () => import('@/views/shared/BaseLayout.vue'),
  },

  setup() {
    const edgeStack = useEdgeStack()

    const addEdit = getAddEditObject({ esId: 'taskAddEdit' })

    function onOpenES({ esId = 'taskAddEdit' } = {}) {
      const openId = esId
      const closeId = openId === 'taskAddEdit' ? 'taskView' : 'taskAddEdit'

      edgeStack.close(closeId)
      // edgeStack.emitter.on(edgeStack.getEventName('closed', closeId), () => {
      edgeStack.open(openId)
      // })
    }

    return {
      addEdit,
      onOpenES,
      getEndpoint: useEndpoints.vehicle.index() + '?bike_category=P&',
      getEndpointOptions: {
        append: '',
        prepend: '',
      },
    }
  },

  render() {
    // const viewTask = () => this.onOpenES({ esId: 'taskView' })
    // const editTask = () => this.onOpenES({ esId: 'taskAddEdit' })
    return (
      <BaseLayout>
        <TaskList />
      </BaseLayout>
    )
  },
})
</script>
