import { reactive } from '@vue/composition-api'

export const getAddEditObject = ({ esId = 'add-edit' } = {}) => {
  const addEdit = reactive({
    // EdgeStack component
    esId,

    // XStepper component
    stepperMode: 'strict',
    stepperStep: 1,

    // Data
    busy: true,
    rawData: null,
    primaryKey: null,
  })

  return addEdit
}
